import LocationService from "../../../services/LocationService";

export const setOpenModalTrue = () => ({
  type: "OPEN_MODAL",
});

export const setOpenModalFalse = () => ({
  type: "CLOSE_MODAL",
});

export const setCallCenterModalTrue = (opsId, lang) => async (dispatch) => {
  if (opsId) {
    try {
      const res = await LocationService.getCallCenter(opsId, lang);
      dispatch({
        type: "SHOW_CALL_CENTER",
        payload: res.data,
      });
    } catch (e) {
      console.log(e);
    }
  }
};

export const setCallCenterModalFalse = () => ({
  type: "HIDE_CALL_CENTER",
});
