import React from "react";
import { Redirect, Route } from "react-router-dom";
// import { useSelector } from "react-redux";
//ionic
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
//components
import Home from "./pages/Home";
import ThankYouPage from "./pages/ThankYouPage";
import InvalidToken from "./pages/InvaidToken";
//selectors
// import { getLanguage } from "../src/store/modal/selectors/SwitchLanguageSelector";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.scss";

const App = () => {
  // const currentLang = useSelector((state) => getLanguage({ state }));

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet animated="false">
          <Route
            exact
            path=""
            component={() => {
              window.location.href = "https://floward.com";
              return null;
            }}
          />
          <Redirect exact path="/:lang/address/:token" to="/:lang/:token" />
          <Route exact path="/:lang/:token" component={Home} />
          <Route path="/:lang/:token/done" component={ThankYouPage} />
          <Route exact path="/:lang/:token/updated" component={InvalidToken} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
