import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { language } from "../shared/Language";
import mapStyle from "../constants/mapStyle.json";
//react google maps
import { withGoogleMap, GoogleMap, withScriptjs } from "react-google-maps";
import Autocomplete from "react-google-autocomplete";
import Geocode from "react-geocode";
//ionic components
import {
  IonButton,
  IonRow,
  IonCol,
  IonToast,
  IonIcon,
  IonImg,
  IonLoading,
} from "@ionic/react";
import { arrowBackOutline, navigateCircleOutline } from "ionicons/icons";
import { countryId } from "../shared/countryId";
//assets
import marker from "../assets/images/marker.png";
//actions
import {
  setMapModalOpenfalse,
  setToastModalOpenfalse,
  setToastModalOpenTrue,
} from "../store/modal/actions/MapModalAction";
import {
  setUserAddress,
  setUserTempAddress,
} from "../store/location/actions/UserLocationAction";
//selectors
import {
  getUserLatitude,
  getUserLongitude,
  getOpsId,
} from "../store/userData/selectors/userDataSelector";
import {
  getUserAddress,
  getUserTempAddress,
  getAreaId,
} from "../store/location/selectors/UserLocationSelector";
import { getToastOpen } from "../store/modal/selectors/MapModalSelector";
import { getLanguage } from "../store/modal/selectors/SwitchLanguageSelector";

let GoogleMapsAPI = "AIzaSyAU4sXTG1XBwWin0hk4tAcUo9PbhSZL3gQ";

const AsyncMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      google={props.google}
      ref={props.onMapMounted}
      defaultZoom={17}
      defaultCenter={{ lat: props.latValue, lng: props.lngValue }}
      disableDefaultUI={false}
      onDragEnd={props.handleDrag}
      options={{
        streetViewControl: false,
        scaleControl: true,
        fullscreenControl: false,
        zoomControl: false,
        mapTypeControl: false,
        rotateControl: true,
        styles: mapStyle,
      }}
    >
      <Autocomplete
        className="map-auto-complete"
        onPlaceSelected={props.onPlaceSelected}
        types={["(regions)"]}
        componentRestrictions={{ country: countryId(props.opsId) }}
        onChange={(e) => props.setText(e.target.value)}
        value={props.text}
      />
    </GoogleMap>
  ))
);

const MapModalContent = ({
  google,
  setUpdatedAreaId,
  setUpdatedAddress,
  setupdatedLat,
  setupdatedLng,
  lang,
  updatedLat,
  updatedLng,
}) => {
  const lat = useSelector((state) => getUserLatitude({ state })),
    lng = useSelector((state) => getUserLongitude({ state })),
    userAddress = useSelector((state) => getUserAddress({ state })),
    userTempAddress = useSelector((state) => getUserTempAddress({ state })),
    areaId = useSelector((state) => getAreaId({ state })),
    opsId = useSelector((state) => getOpsId({ state })),
    openToast = useSelector((state) => getToastOpen({ state })),
    currentLang = useSelector((state) => getLanguage({ state })),
    [latValue, setLatValue] = useState(lat),
    [lngValue, setLngValue] = useState(lng),
    [text, setText] = useState(""),
    dispatch = useDispatch(),
    [showLoading, setShowLoading] = useState(false),
    refs = {};
  Geocode.setApiKey(GoogleMapsAPI);

  const onMapMounted = (ref) => {
    refs.map = ref;
  };

  useEffect(() => {
    if (updatedLat && updatedLng) {
      setLatValue(updatedLat);
      setLngValue(updatedLng);
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          dispatch(
            setUserTempAddress({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              opsId,
            })
          );
          if (refs.map) {
            setLatLng(position.coords.latitude, position.coords.longitude);
          }
          setLatValue(position.coords.latitude);
          setLngValue(position.coords.longitude);
        });
      } else {
        dispatch(setUserTempAddress({ lat, lng, opsId }));
        setLatLng(lat, lng);
        setLatValue(lat);
        setLngValue(lng);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetUserAddress = async () => {
    dispatch({
      type: "ADDRESS_EXTRA_DETAILS",
      payload: "",
    });
    setShowLoading(true);
    try {
      await dispatch(setUserAddress({ lat: latValue, lng: lngValue, opsId }));
      setUpdatedAreaId(areaId);
      setUpdatedAddress(userAddress);
      setupdatedLat(latValue);
      setupdatedLng(lngValue);
      setShowLoading(false);
      dispatch(setMapModalOpenfalse());
    } catch (err) {
      setShowLoading(false);
    }
  };

  const handleDrag = () => {
    const center = refs.map.getCenter();
    let newLat = center.lat(),
      newLng = center.lng();
    dispatch(setUserTempAddress({ lat: newLat, lng: newLng, opsId }));
    setLatValue(newLat);
    setLngValue(newLng);
  };

  const onPlaceSelected = (place) => {
    if (place && place.geometry) {
      let newLat = place.geometry.location.lat(),
        newLng = place.geometry.location.lng();
      dispatch(setUserTempAddress({ lat: newLat, lng: newLng, opsId }));
      if (refs.map) {
        setLatLng(newLat, newLng);
      }
      setText("");
      setLatValue(newLat);
      setLngValue(newLng);
    } else {
      if (
        text.match(
          /^([-+]?)([\d]{1,2})(((\.)(\d+)(,)))(\s*)(([-+]?)([\d]{1,3})((\.)(\d+))?)$/g
        )
      ) {
        var latlongArray = text.split(",");
        var newLat = parseFloat(latlongArray[0]);
        var newLng = parseFloat(latlongArray[1]);
        dispatch(setUserTempAddress({ lat: newLat, lng: newLng, opsId }));
        if (refs.map) {
          setLatLng(newLat, newLng);
        }
        setText("");
        setLatValue(newLat);
        setLngValue(newLng);
      } else {
        Geocode.fromAddress(text).then(
          (response) => {
            const { lat, lng } = response.results[0].geometry.location;
            dispatch(setUserTempAddress({ lat: lat, lng: lng, opsId }));
            if (refs.map) {
              setLatLng(lat, lng);
            }
            setText("");
            setLatValue(lat);
            setLngValue(lng);
          },
          (error) => {
            dispatch(setToastModalOpenTrue());
          }
        );
      }
    }
  };

  const navigateCurrentPoistion = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      dispatch(
        setUserTempAddress({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          opsId,
        })
      );
      if (refs.map) {
        setLatLng(position.coords.latitude, position.coords.longitude);
      }
      setLatValue(position.coords.latitude);
      setLngValue(position.coords.longitude);
    });
  };

  const setLatLng = (lat, lng) => {
    refs.map.panTo({ lat, lng });
  };

  return (
    <div className="map-modal-content-wrapper">
      {lat !== undefined && (
        <Fragment>
          <AsyncMap
            latValue={latValue}
            lngValue={lngValue}
            handleDrag={handleDrag}
            onPlaceSelected={onPlaceSelected}
            google={google}
            opsId={opsId}
            onMapMounted={onMapMounted}
            setText={setText}
            text={text}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: "100%" }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
          <IonToast
            isOpen={openToast}
            onDidDismiss={() => dispatch(setToastModalOpenfalse())}
            message={
              lang === "en"
                ? "Sorry, we don't cover this area"
                : "نعتذر ، لا نوفر توصيل لهذه المنطقه"
            }
            position="bottom"
            color="danger"
            duration={2000}
          />
          <IonLoading
            cssClass="my-custom-class"
            isOpen={showLoading}
            onDidDismiss={() => setShowLoading(false)}
            message={"Please wait..."}
          />
          <IonImg src={marker} className="marker-style-center" />
          <IonIcon
            style={{
              fontSize: 30,
              color: "#0c697e",
              position: "absolute",
              top: 26,
              left: 14,
            }}
            onClick={() => dispatch(setMapModalOpenfalse())}
            icon={arrowBackOutline}
          />
          <IonIcon
            style={{
              fontSize: 50,
              color: "#0c697e",
              position: "absolute",
              bottom: 110,
              right: 10,
            }}
            onClick={navigateCurrentPoistion}
            icon={navigateCircleOutline}
          />
          <div className="confirm-wrapper">
            <h2 style={{ textAlign: "center", fontSize: 16, color: "black" }}>
              {userTempAddress}
            </h2>
            <IonRow style={{ width: "100%" }}>
              <IonCol size="12">
                <IonButton
                  color="primary"
                  shape="round"
                  expand="full"
                  strong="true"
                  onClick={GetUserAddress}
                >
                  {language[currentLang].confirmButton}
                </IonButton>
              </IonCol>
            </IonRow>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default React.memo(MapModalContent);
