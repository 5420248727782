import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
//ionic
import { IonHeader, IonToolbar, IonImg, IonButton } from "@ionic/react";
import logo from "../assets/images/logo.png";
//actions
import { setLanguge } from "../store/modal/actions/SwitchLanguageAction";
import { setCallCenterModalTrue } from "../store/modal/actions/OpenCloseModalAction";
//selectors
import { getLanguage } from "../store/modal/selectors/SwitchLanguageSelector";
//assets
import hand from "../assets/images/hand.png";
import { getOpsId } from "../store/userData/selectors/userDataSelector";

const HomeHeader = ({
  history,
  match: {
    params: { token, lang },
  },
}) => {
  const currentLang = useSelector((state) => getLanguage({ state })),
    opsId = useSelector((state) => getOpsId({ state })),
    dispatch = useDispatch();

  const updateLanguage = (language) => {
    dispatch(setLanguge(language));
    history.push(`/${language}/${token}`);
  };

  const langHeader = lang === "en" ? 1 : 0;

  const openCallCenterModal = () => {
    dispatch(setCallCenterModalTrue(opsId, langHeader));
  };

  return (
    <IonHeader>
      <IonToolbar>
        {currentLang === "en" && (
          <Ion-Text onClick={() => updateLanguage("ar")} slot="end">
            العربية
          </Ion-Text>
        )}

        {currentLang === "ar" && (
          <Ion-Text
            onClick={() => updateLanguage("en")}
            slot="end"
            style={{ cursor: "pointer" }}
          >
            English
          </Ion-Text>
        )}
        <IonImg className="logo" src={logo} />
        <IonButton
          className="btncolor"
          slot="start"
          onClick={openCallCenterModal}
          fill="clear"
        >
          <IonImg className="handset-icons" src={hand} />
        </IonButton>
      </IonToolbar>
    </IonHeader>
  );
};

export default withRouter(HomeHeader);
