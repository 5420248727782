import { updateObject } from "../../utility";

const initialState = {
  isLoaderOpen: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_LOADER": {
      return updateObject(state, { isLoaderOpen: true });
    }
    case "CLOSE_LOADER": {
      return updateObject(state, { isLoaderOpen: false });
    }
    default:
      return state;
  }
};

export default reducer;
