import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { language } from "../shared/Language";
//ionic
import {
  IonTextarea,
  IonInput,
  IonLabel,
  IonItem,
  IonRow,
  IonGrid,
  IonCheckbox,
  IonCol,
} from "@ionic/react";
//selectors
import { getLanguage } from "../store/modal/selectors/SwitchLanguageSelector";
import { getAddressExtraDetails } from "../store/addressdetails/AddressDetailsSelector";
import { getCountryId } from "../store/userData/selectors/userDataSelector";

const AddressDetails = ({
  updatedAddress,
  setUpdatedAddress,
  setPostalCode,
  isDefaultAddress,
  setIsDefaultAddress,
}) => {
  const currentLang = useSelector((state) => getLanguage({ state })),
    addressExtraDetails = useSelector((state) =>
      getAddressExtraDetails({ state })
    ),
    countryId = useSelector((state) => getCountryId({ state })),
    dispatch = useDispatch();

  const handleAddressDetails = ({ target: { value } }) => {
    setUpdatedAddress(value);
    dispatch({
      type: "ADDRESS_DETAILS",
      payload: value,
    });
  };

  const handleExtraDetails = ({ target: { value } }) =>
    dispatch({
      type: "ADDRESS_EXTRA_DETAILS",
      payload: value,
    });

  return (
    <Fragment>
      {(updatedAddress || countryId === 8) && (
        <div style={{ margin: 0, width: "100%" }}>
          {countryId == 8 && (
            <IonRow>
              <IonItem style={{ width: "100%" }}>
                <IonLabel position="floating">Postal code</IonLabel>
                <IonInput
                  className="text-area"
                  onIonChange={(e) => setPostalCode(e.target.value)}
                />
              </IonItem>
            </IonRow>
          )}
          <IonRow>
            <IonItem style={{ width: "100%", marginLeft: -5 }}>
              <IonLabel
                style={{
                  alignSelf: currentLang === "ar" ? "flex-end" : "flex-start",
                }}
                position="floating"
              >
                {language[currentLang].addressPlaceHolder}
              </IonLabel>
              <IonTextarea
                className="text-area"
                style={{
                  textAlign: currentLang === "ar" ? "right" : "left",
                  paddingLeft: 10,
                }}
                rows="3"
                value={updatedAddress}
                onIonChange={(e) => handleAddressDetails(e)}
              ></IonTextarea>
            </IonItem>
          </IonRow>
          <IonRow>
            <IonItem style={{ width: "100%", marginLeft: -5 }}>
              <IonLabel
                style={{
                  alignSelf: currentLang === "ar" ? "flex-end" : "flex-start",
                }}
                position="floating"
              >
                {language[currentLang].extraAddressDetails}
              </IonLabel>
              <IonTextarea
                className="text-area"
                style={{
                  textAlign: currentLang === "ar" ? "right" : "left",
                  paddingLeft: 10,
                }}
                onIonChange={(e) => handleExtraDetails(e)}
                rows="3"
                value={addressExtraDetails}
              ></IonTextarea>
            </IonItem>
          </IonRow>
          <IonRow className="default-address">
            <IonCol size="9">
              <h3 style={{ fontSize: 16, color: "#f4aa24", margin: 0 }}>
                {language[currentLang].defaultAddress}
              </h3>
              <p style={{ fontSize: 12, color: "#f4aa24" }}>
                {language[currentLang].defaultAddressDesc}
              </p>
            </IonCol>
            <IonCol size="3">
              <IonItem>
                <IonCheckbox
                  onIonChange={() => setIsDefaultAddress((prev) => !prev)}
                  checked={isDefaultAddress}
                  slot="end"
                  color="primary"
                />
              </IonItem>
            </IonCol>
          </IonRow>
        </div>
      )}
    </Fragment>
  );
};

export default AddressDetails;
