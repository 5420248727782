import LocationService from "../../../services/LocationService";
import { setToastModalOpenTrue } from "../../modal/actions/MapModalAction";

export const setUserAddress = ({ lat, lng, opsId }) => async (dispatch) => {
  try {
    const res = await LocationService.getUserLocation({ lat, lng, opsId });
    dispatch({
      type: "GET_USER_ADDRESS",
      payload: res.data,
    });
  } catch (e) {
    dispatch(setToastModalOpenTrue());
  }
};

export const setUserTempAddress = ({ lat, lng, opsId }) => async (dispatch) => {
  try {
    const res = await LocationService.getUserLocation({ lat, lng, opsId });
    dispatch({
      type: "GET_USER_TEMP_ADDRESS",
      payload: res.data,
    });
  } catch (e) {
    dispatch(setToastModalOpenTrue());
  }
};
