export const setMapModalOpenTrue = () => ({
  type: "OPEN_MAP_MODAL",
});

export const setMapModalOpenfalse = () => ({
  type: "CLOSE_MAP_MODAL",
});

export const setToastModalOpenTrue = () => ({
  type: "OPEN_TOAST_MODAL",
});

export const setToastModalOpenfalse = () => ({
  type: "CLOSE_TOAST_MODAL",
});
