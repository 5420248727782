import LocationService from "../../../services/LocationService";
//actions
import { setMapModalOpenTrue } from "../../modal/actions/MapModalAction";
import {
  setOpenLoaderFalse,
  setOpenLoaderTrue,
} from "../../modal/actions/OpenCloseLoaderAction";
//selectors
import {
  getUserLatitude,
  getUserLongitude,
} from "../selectors/userDataSelector";

export const setUserData = (token, lang) => async (dispatch) => {
  try {
    const res = await LocationService.getUserData(token);
    dispatch({
      type: "USER_DATA",
      payload: res.data,
    });
    const countryID = res.data.ops.countryID;
    const opsId = res.data.opsId;
    if (
      countryID === 2 ||
      countryID === 6 ||
      countryID === 4 ||
      countryID === 3
    ) {
      dispatch(setMapModalOpenTrue());
    } else {
      const areasList = await LocationService.getAreasList(countryID, opsId);
      dispatch({
        type: "AREAS_LIST",
        payload: areasList.data,
      });
    }
  } catch (error) {
    window.location.href = `/${lang}/${token}/updated`;
    console.log(error);
  }
};

export const setMapWithUserData = () => async (dispatch, getState) => {
  dispatch(setOpenLoaderTrue());
  const state = getState(),
    lat = getUserLatitude({ state }),
    lng = getUserLongitude({ state });

  if (lat && lng) {
    dispatch(setMapModalOpenTrue());
    dispatch(setOpenLoaderFalse());
  }
};
