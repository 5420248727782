const BASE_API_URL = "/api";

//user data
const ADDRESS = "/Address";
export const getUserDataUrl = (token) => `${BASE_API_URL}${ADDRESS}/${token}`;

//user location
const MAP = "/map",
  DROP = "/drop";
export const getUserLocationUrl = ({ lat, lng, opsId }) =>
  `${BASE_API_URL}${ADDRESS}${MAP}${DROP}?lat=${lat}&lng=${lng}&opsId=${opsId}`;

//areas list
const COUNTRY = "/Country",
  GETAREALIST = "/GetAreaList";
export const getAreasListUrl = (countryId) =>
  `${BASE_API_URL}${COUNTRY}${GETAREALIST}/${countryId}/2`;

//send data
const CHANGEADDRESS = "/ChangeAddress";
export const getSendDataUrl = () => `${BASE_API_URL}${ADDRESS}${CHANGEADDRESS}`;

//call center
const OPSCENTER = "/OpsCenter",
  CUSTOMERCARE = "/CustomerCare";
export const getCallCenterUrl = () =>
  `${BASE_API_URL}${OPSCENTER}${CUSTOMERCARE}`;
