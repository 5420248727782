import axios from "axios";
import { APP_CONFIG } from "../services/Config";

// let apiService, areaService;

// if (
//   window.location.href.indexOf("sandbox") > -1 ||
//   window.location.hostname === "localhost" ||
//   window.location.href.indexOf("staging") > -1
// ) {
//   apiService = axios.create({
//     baseURL: APP_CONFIG.SANDBOX.order,
//   });
//   areaService = axios.create({
//     baseURL: APP_CONFIG.SANDBOX.home,
//   });
// } else {
//   apiService = axios.create({
//     baseURL: APP_CONFIG.PRODUCTION.order,
//   });
//   areaService = axios.create({
//     baseURL: APP_CONFIG.PRODUCTION.home,
//   });
// }

const apiService = axios.create({
	baseURL: APP_CONFIG.apiBasePaths.order,
});
const areaService = axios.create({
	baseURL: APP_CONFIG.apiBasePaths.home,
});

export { apiService, areaService };
