import { updateObject } from "../../utility";

const initialState = {
  orderId: "",
  name: "",
  deliveryDate: "",
  deliveryTime: "",
  opsId: "",
  lat: "",
  lng: "",
  countryId: "",
  opsData: {},
  areasList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_DATA": {
      return updateObject(state, {
        orderId: action.payload.id,
        name: action.payload.name,
        deliveryDate: action.payload.deliveryDate,
        deliveryTime: action.payload.deliveryTime,
        opsId: action.payload.opsId,
        lat: +action.payload.ops.lat,
        lng: +action.payload.ops.lng,
        countryId: action.payload.ops.countryID,
        opsData: action.payload.ops
      });
    }

    case "AREAS_LIST": {
      return updateObject(state, {
        areasList: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
