import React from "react";
//ionic components
import { IonContent, IonModal } from "@ionic/react";

const Modal = ({
  isOpen,
  cssClass,
  handleCloseModal,
  modalContent,
  swipeToClose,
}) => {
  return (
    <IonContent>
      <IonModal
        isOpen={isOpen}
        cssClass={cssClass}
        swipeToClose={swipeToClose}
        onDidDismiss={() => handleCloseModal()}
        animated={true}
      >
        {modalContent}
      </IonModal>
    </IonContent>
  );
};

export default Modal;
