import React from "react";
import { useSelector } from "react-redux";
import { language } from "../shared/Language";
//selectors
import { getUserName } from "../store/userData/selectors/userDataSelector";
import { getLanguage } from "../store/modal/selectors/SwitchLanguageSelector";

const GiftMessage = () => {
  const userName = useSelector((state) => getUserName({ state })),
    currentLang = useSelector((state) => getLanguage({ state }));

  return (
    <div
      style={{ padding: "0 20px", height: "auto", marginTop: 15 }}
      className="message-wrapper"
    >
      <div
        style={{
          display: "flex",
          fontSize: 18,
          flexDirection: currentLang === "ar" ? "row-reverse" : "",
        }}
        size={12}
      >
        <span>{language[currentLang].welcomeMessage}</span>
        <span>&nbsp;</span>
        <span>{userName}</span>
      </div>
      <h3
        style={{
          textAlign: currentLang === "ar" ? "right" : "left",
        }}
      >
        {language[currentLang].welcomeContent}
      </h3>
    </div>
  );
};

export default GiftMessage;
