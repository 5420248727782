import React from "react";
import { withRouter } from "react-router";
import { useSelector } from "react-redux";
import { language } from "../shared/Language";
//ionic components
import { IonGrid, IonRow, IonCol, IonImg } from "@ionic/react";
//assets
import thank from "../assets/images/thank.gif";
//selectors
import { getUserAddress } from "../store/location/selectors/UserLocationSelector";
import {
  getDeliveryTime,
  getDeliveryDate,
} from "../store/userData/selectors/userDataSelector";
import {
  getAddressDetails,
  getAddressExtraDetails,
} from "../store/addressdetails/AddressDetailsSelector";
import { getLanguage } from "../store/modal/selectors/SwitchLanguageSelector";

const ThanksMessage = ({match: {
  params: {lang },
},}) => {
  const userAddress = useSelector((state) => getUserAddress({ state })),
    deliveryDate = useSelector((state) => getDeliveryDate({ state })),
    deliveryTime = useSelector((state) => getDeliveryTime({ state })),
    addressDetails = useSelector((state) => getAddressDetails({ state })),
    currentLang = useSelector((state) => getLanguage({ state })),
    addressExtraDetails = useSelector((state) =>
      getAddressExtraDetails({ state })
    );

  return (
    <IonGrid className="thank-message-wrapper">
      <IonRow>
        <IonCol style={{ padding: 4 }}>
          <h1 className="thank-message-title">
            {language[lang].thanksMessage}
          </h1>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonImg src={thank} />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <h2
            className="thank-message-title"
            style={{ fontSize: 20, width: "60%", marginBottom: 30 }}
          >
            {language[lang].thanksContent}
            
          </h2>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <h3>
          {language[currentLang].delivery}
          </h3>
          <h4>
            {deliveryDate}
            <span>&nbsp;</span>
            <span>{deliveryTime}</span>
          </h4>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <h3>
          {language[lang].deliverTo}
          </h3>
          <h4>{addressDetails ? addressDetails : userAddress}</h4>
          <h4>{addressExtraDetails}</h4>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default withRouter(ThanksMessage);
