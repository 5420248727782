import { updateObject } from "../../utility";

const initialState = {
  isMapModalOpen: false,
  isToastOpen: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_MAP_MODAL": {
      return updateObject(state, { isMapModalOpen: true });
    }
    case "CLOSE_MAP_MODAL": {
      return updateObject(state, { isMapModalOpen: false });
    }
    case "OPEN_TOAST_MODAL": {
      return updateObject(state, { isToastOpen: true });
    }
    case "CLOSE_TOAST_MODAL": {
      return updateObject(state, { isToastOpen: false });
    }
    default:
      return state;
  }
};

export default reducer;
