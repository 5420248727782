import React, { useRef, useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { countryId } from "../shared/countryId";
//actions
import {
  setToastModalOpenfalse,
  setToastModalOpenTrue,
} from "../store/modal/actions/MapModalAction";
//selectors
import { getOpsId } from "../store/userData/selectors/userDataSelector";
import { getToastOpen } from "../store/modal/selectors/MapModalSelector";
//ionic components
import { IonToast, IonRow, IonLoading } from "@ionic/react";
//api
import LocationService from "../services/LocationService";

const DropByWords = ({
  setUpdatedAreaId,
  setUpdatedAddress,
  setupdatedLat,
  setupdatedLng,
  lang,
}) => {
  const convert = useRef(),
    dispatch = useDispatch(),
    openToast = useSelector((state) => getToastOpen({ state })),
    opsId = useSelector((state) => getOpsId({ state })),
    [wordsValue, setWordsValue] = useState(""),
    [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    convert.current.addEventListener("select", (value) => {
      handleConvertToCoords(value, opsId);
    });
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opsId]);

  const handleConvertToCoords = async (value) => {
    if (value.detail && opsId) {
      dispatch({
        type: "ADDRESS_EXTRA_DETAILS",
        payload: "",
      });
      setShowLoading(true);
      try {
        const res = await Axios.get(
          `https://api.what3words.com/v3/convert-to-coordinates?words=${value.detail}&key=6QQVMHP8`
        );

        const response = await LocationService.getUserLocation({
          lat: res.data.coordinates.lat,
          lng: res.data.coordinates.lng,
          opsId,
        });
        setUpdatedAreaId(response.data.areaId);
        setUpdatedAddress(response.data.addressLineOne);
        setupdatedLat(res.data.coordinates.lat);
        setupdatedLng(res.data.coordinates.lng);
        setShowLoading(false);
      } catch (err) {
        dispatch(setToastModalOpenTrue());
        setShowLoading(false);
        setUpdatedAddress("");
      }
    }
  };

  return (
    <Fragment>
      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonToast
        isOpen={openToast}
        onDidDismiss={() => dispatch(setToastModalOpenfalse())}
        message={
          lang === "en"
            ? "Sorry, we don't cover this area"
            : "نعتذر ، لا نوفر توصيل لهذه المنطقه"
        }
        position='bottom'
        color='danger'
        duration={2000}
      />
      <IonRow>
        <div
          style={{
            textAlign: "center",
            margin: "0 auto",
            fontSize: 14,
            fontWeight: "bold",
          }}>
          <p style={{ marginBottom: 0, marginTop: 10 }}>
            {lang === "en" ? "Or use" : " او"}
          </p>
          <p style={{ margin: "0 auto", marginTop: 5, marginBottom: 3 }}>
            {lang === "en" ? "what3words" : " استخدم تطبيق الثلاث كلمات"}
          </p>
        </div>

        <what3words-autosuggest
          ref={convert}
          id='suggestComponent'
          placeholder='index.home.raft'
          clip-to-country={countryId(opsId)}
          onChange={(e) => setWordsValue(e.target.value)}
          value={wordsValue}
        />
      </IonRow>
    </Fragment>
  );
};

export default DropByWords;
