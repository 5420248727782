import React from "react";
//components
import InvalidTokenMessage from "../components/InvalidTokenMessage";
//ionic components
import { IonPage } from "@ionic/react";

const InvalidToken = () => {
  return (
    <IonPage className="app-wrapper">
      <InvalidTokenMessage />
    </IonPage>
  );
};

export default InvalidToken;
