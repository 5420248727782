import { updateObject } from "../../utility";

const initialState = {
  area: "",
  address: "",
  tempAddress: "",
  countryId: "",
  areaId: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USER_ADDRESS": {
      return updateObject(state, {
        area: action.payload.area.name,
        address: action.payload.addressLineOne,
        countryId: action.payload.countryID,
        areaId: action.payload.areaId,
      });
    }
    case "GET_USER_TEMP_ADDRESS": {
      return updateObject(state, {
        tempAddress: action.payload.addressLineOne,
        areaId: action.payload.areaId,
        address: action.payload.addressLineOne,
      });
    }
    default:
      return state;
  }
};

export default reducer;
