import React from "react";
import { useDispatch, useSelector } from "react-redux";
//components
import ThanksMessage from "../components/ThanksMessage";
//ionic components
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonItem,
  IonImg,
  IonButton,
} from "@ionic/react";
//assets
import hand from "../assets/images/hand.png";
import home from "../assets/images/home.png";
//actions
import { setCallCenterModalTrue } from "../store/modal/actions/OpenCloseModalAction";
import { getOpsId } from "../store/userData/selectors/userDataSelector";

const ThankYouPage = ({ history }) => {
  const opsId = useSelector((state) => getOpsId({ state })),
    dispatch = useDispatch();

  const openCallCenterModal = () => {
    dispatch(setCallCenterModalTrue(opsId));
  };
  return (
    <IonPage className="app-wrapper">
      <IonHeader>
        <IonToolbar>
          <IonItem slot="end" onClick={() => history.push("/")}>
            <IonImg className="home-icons" src={home} />
          </IonItem>
          <IonButton
            className="btncolor"
            onClick={openCallCenterModal}
            slot="start"
            fill="clear"
          >
            <IonImg className="handset-icons" src={hand} />
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <ThanksMessage />
    </IonPage>
  );
};

export default ThankYouPage;
