export const getUserOrderId = ({ state }) => state.userData.orderId;
export const getUserName = ({ state }) => state.userData.name;
export const getDeliveryDate = ({ state }) => state.userData.deliveryDate;
export const getDeliveryTime = ({ state }) => state.userData.deliveryTime;
export const getOpsId = ({ state }) => state.userData.opsId;
export const getUserLatitude = ({ state }) => state.userData.lat;
export const getUserLongitude = ({ state }) => state.userData.lng;
export const getAreasList = ({ state }) => state.userData.areasList;
export const getCountryId = ({ state }) => state.userData.countryId;
export const getOpsData = ({ state }) => state.userData.opsData;
