import { combineReducers } from "redux";

import modalReducer from "./modal/reducers/OpenCloseModalReducer";
import mapModalReducer from "./modal/reducers/MapModalReducer";
import loaderReducer from "./modal/reducers/OpenCloseLoaderReducer";
import userLocationReducer from "./location/reducers/UserLocationReducer";
import userData from "./userData/reducers/userDataReducer";
import languageReducer from "./modal/reducers/SwitchLanguageReducer"
import addressDetailsReducer from "./addressdetails/AddressDetailsReducer";


const rootReducer = combineReducers({
  modalReducer,
  mapModalReducer,
  loaderReducer,
  userLocationReducer,
  userData,
  languageReducer,
  addressDetailsReducer
});

export default rootReducer;
