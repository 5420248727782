// Configure your environment
export const APP_CONFIG = {
	// Base APP URL
	URL: {
		base: '//localhost:3000',
	},
	apiBasePaths: {
		v1: process.env.REACT_APP_v1,
		v2: process.env.REACT_APP_v2,
		Subscription: process.env.REACT_APP_Subscription,
		product: process.env.REACT_APP_product,
		corepayment: process.env.REACT_APP_corepayment,
		order: process.env.REACT_APP_order,
		home: process.env.REACT_APP_home,
		user: process.env.REACT_APP_user,
	},
	// API request SANDBOX
	SANDBOX: {
		v1: 'https://alpha.floward.io',
		v2: 'https://alpha.floward.io',
		Subscription: 'https://sandboxsubsc.floward.io',
		product: 'https://sandboxproduct.floward.io',
		corepayment: 'https://sandboxpaycore.floward.io',
		order: 'https://sandboxorder.floward.io',
		home: 'https://sandboxhome.floward.io',
		user: 'https://sandboxuser.floward.io',
	},
	// API request LIVE
	PRODUCTION: {
		v1: 'https://api.floward.io',
		v2: 'https://api.floward.io',
		Subscription: 'https://subsc.floward.io',
		product: 'https://product.floward.io',
		corepayment: 'https://paycore.floward.io',
		order: 'https://order.floward.io',
		home: 'https://home.floward.io',
		user: 'https://user.floward.io',
	},
};
