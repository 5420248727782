import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { language } from "../shared/Language";
//ionic components
import { IonItem, IonImg, IonText, IonLoading, IonButton } from "@ionic/react";
import {
    getDeliveryDate,
    getDeliveryTime,
    getOpsData,
} from "../store/userData/selectors/userDataSelector";
//actions
// import { setOpenModalTrue } from "../store/modal/actions/OpenCloseModalAction";
import { setMapWithUserData } from "../store/userData/actions/userDataAction";
//selectors
import { getLanguage } from "../store/modal/selectors/SwitchLanguageSelector";
import { getCountryId } from "../store/userData/selectors/userDataSelector";

const DeliverTime = () => {
  const [showLoading, setShowLoading] = useState(true),
    dispatch = useDispatch(),
    deliveryDate = useSelector((state) => getDeliveryDate({ state })),
    deliveryTime = useSelector((state) => getDeliveryTime({ state })),
    currentLang = useSelector((state) => getLanguage({ state })),
    countryId = useSelector((state) => getCountryId({ state })),
    opsData = useSelector(state => getOpsData({state})),
    opsTitle = currentLang === 'ar' ? opsData.titleAr : opsData.title ? opsData.title : '';

    useEffect(() => {
    if (deliveryTime) {
      setShowLoading(false);
    }
  }, [deliveryTime]);

  const handleMapModal = () => {
    dispatch(setMapWithUserData());
  };

  return (
    <div style={{ padding: "0 10px", height: "auto", marginTop: 15 }}>
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <div style={{ padding: "0 15px" }}>
        <IonText>
          <h3
            className="deliver-time-title"
            style={{
              textAlign: currentLang === "ar" ? "right" : "left",
              color: "black",
            }}
          >
            {language[currentLang].deliverTimeTitle}
          </h3>
        </IonText>

        <div className="deliver-time-wrapper">
          <span className="selected-time">
            <span>{deliveryDate}</span>
            <span>&nbsp;</span>
            <span>{deliveryTime}</span>
          </span>
        </div>
      </div>
        <IonText>
            <h3
                className="deliver-time-title"
                style={{
                    textAlign: currentLang === "ar" ? "right" : "left",
                    paddingRight: currentLang === "ar" ? 15 : 0,
                    paddingLeft: currentLang !== "ar" ? 15 : 0,
                    color: "black",
                }}
            >
                {language[currentLang].deliverToOps}: {opsTitle}
            </h3>
        </IonText>
      <IonItem
        style={{
          display: countryId === 8 ? "none" : "",
          position: "relative",
        }}
        className="map-img-wrapper"
        onClick={handleMapModal}
      >
        <IonImg
          className="map-img"
          src="https://cdn.floward.com/web/Files/attachment/group-72299@3x-637503596992928369.jpg"
        />
        <IonButton
          className="find-location-btn"
          color="primary"
          shape="round"
          expand="full"
        >
          {currentLang === "ar" ? "اختر الموقع" : "Find Location"}
        </IonButton>
      </IonItem>
    </div>
  );
};

export default withRouter(DeliverTime);
