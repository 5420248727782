import { updateObject } from "../../utility";

const initialState = {
  currentLang: "ar",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SWITCH_LANGUAGE": {
      return updateObject(state, { currentLang: action.payload });
    }
    default:
      return state;
  }
};

export default reducer;
