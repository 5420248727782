import React from "react";
import { withRouter } from "react-router";
import { language } from "../shared/Language";
//ionic components
import { IonGrid, IonRow, IonCol, IonImg, IonButton } from "@ionic/react";
//assets
import thank from "../assets/images/thank.gif";

const InvalidTokenMessage = ({
  match: {
    params: { lang },
  },
  history,
}) => {
  return (
    <IonGrid
      className="thank-message-wrapper"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
      }}
    >
      <IonRow>
        <IonCol style={{ padding: 4 }}>
          <h1 className="thank-message-title">
            {language[lang].thanksMessage}
          </h1>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonImg src={thank} />
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <h2
            className="thank-message-title"
            style={{ fontSize: 20, width: "60%", marginBottom: 30 }}
          >
            {language[lang].invalidToken}
          </h2>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol>
          <IonButton
            color="primary"
            shape="round"
            expand="block"
            strong="true"
            onClick={() => history.push("/")}
          >
            {lang === "en" ? "Back to home page" : "عوده الي الصفحه الرئيسية"}
          </IonButton>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default withRouter(InvalidTokenMessage);
