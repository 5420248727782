import { updateObject } from "../../utility";

const initialState = {
  isModalOpen: false,
  isCallCenterOpen: false,
  data: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_MODAL": {
      return updateObject(state, { isModalOpen: true });
    }
    case "CLOSE_MODAL": {
      return updateObject(state, { isModalOpen: false });
    }
    case "SHOW_CALL_CENTER": {
      return updateObject(state, {data: action.payload,  isCallCenterOpen: true });
    }
    case "HIDE_CALL_CENTER": {
      return updateObject(state, { isCallCenterOpen: false });
    }
    default:
      return state;
  }
};

export default reducer;
