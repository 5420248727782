export const countryId = (opsId) => {
  switch (opsId) {
    case 1:
      return "kw";
    case 2:
      return "sa";
    case 3:
      return "sa";
    case 4:
      return "bh";
    case 5:
      return "qa";
    case 6:
      return "sa";
    case 7:
      return "ae";
    case 8:
      return "sa";
    case 9:
      return "ae";
    case 10:
      return "gb";
    case 11:
      return "sa";
    case 12:
      return "sa";
    case 13:
      return "ae";
    case 14:
      return "ae";
    case 15:
      return "ae";
    case 16:
      return "sa";
    case 17:
      return "ae";
    case 19:
      return "om";
    default:
      return "kw";
  }
};
