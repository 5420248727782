import { apiService, areaService } from "./HttpService";
//constants
import {
  getUserDataUrl,
  getUserLocationUrl,
  getAreasListUrl,
  getSendDataUrl,
  getCallCenterUrl,
} from "../constants/ApiUrls";

class LocationService {
  static getUserData(token) {
    return apiService({
      method: "GET",
      url: getUserDataUrl(token),
    });
  }
  static getUserLocation({ lat, lng, opsId }) {
    return apiService({
      method: "GET",
      url: getUserLocationUrl({ lat, lng, opsId }),
      headers: { opsId },
    });
  }
  static getAreasList(countryId, opsId) {
    return areaService({
      method: "GET",
      url: getAreasListUrl(countryId),
      headers: { opsId: opsId },
    });
  }
  static sendData(data) {
    return apiService({
      method: "POST",
      url: getSendDataUrl(),
      data: data,
    });
  }
  static getCallCenter(opsId, lang) {
    return areaService({
      method: "GET",
      url: getCallCenterUrl(),
      headers: { opsId, lang },
    });
  }
}

export default LocationService;
