import React from "react";
import { useSelector } from "react-redux";

//ionic
import { IonGrid, IonRow, IonCol, IonImg } from "@ionic/react";
//selectors
import { getCallCenterData } from "../store/modal/selectors/OpenCloseModalSelector";
import { getLanguage } from "../store/modal/selectors/SwitchLanguageSelector";
//assets
import call from "../assets/images/call.png";
import whatsapp from "../assets/images/whatsapp.png";
import chat from "../assets/images/chat.png";
import email from "../assets/images/email.png";
import faq from "../assets/images/faq.png";

const CallCenterModalContent = ({ lang }) => {
  const callCenterData = useSelector((state) => getCallCenterData({ state })),
    currentLang = useSelector((state) => getLanguage({ state })),
    imgArray = {
      1: call,
      2: whatsapp,
      3: chat,
      4: email,
      5: faq,
    };

  return (
    <div
      className="call-center-modal-wrapper"
      style={{ textAlign: currentLang === "ar" ? "right" : "left" }}
    >
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <h2>
              {lang === "en" ? "We are happy to help you!" : "!سعداء لمساعدتك"}
            </h2>
          </IonCol>
        </IonRow>
        <IonRow style={{ marginBottom: 12 }}>
          <IonCol size="12">
            <h6>
              {lang === "en" ? "Need help with something?" : "تحتاج لمساعده؟"}
            </h6>
          </IonCol>
        </IonRow>
        {callCenterData.map((item, i) => {
          return (
            <IonRow key={item.Id} style={{ marginBottom: 12 }}>
              <IonCol size="12">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent:
                      currentLang === "en" ? "flex-start" : "flex-end",
                  }}
                >
                  <IonImg className="home-icons" src={imgArray[item.Id]} />
                  <a href="tel: item.Value" className="call-center-links">
                    {item.Title}
                  </a>
                </div>
              </IonCol>
            </IonRow>
          );
        })}
      </IonGrid>
    </div>
  );
};

export default CallCenterModalContent;
