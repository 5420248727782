import { updateObject } from "../utility";

const initialState = {
  addressDetails: "",
  addressExtraDetails: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADDRESS_DETAILS": {
      return updateObject(state, {
        addressDetails: action.payload,
      });
    }
    case "ADDRESS_EXTRA_DETAILS": {
      return updateObject(state, {
        addressExtraDetails: action.payload,
      });
    }
    default:
      return state;
  }
};

export default reducer;
