export const language = {
  en: {
    welcomeMessage: "Hello",
    welcomeContent:
      "Someone sent you a gift from Floward. Please fill out your address details.",
    deliverTimeTitle: "Delivery time",
    addressDetailsTitle: "Address details",
    chooseArea: "Choose area",
    addressPlaceHolder: "Address",
    extraAddressDetails: "Extra directions",
    confirmButton: "Confirm",
    saveButton: "Save",
    thanksMessage: "Thank you",
    thanksContent: "Your order has been successfully updated",
    delivery: "Delivery",
    deliverTo: "Deliver to",
    invalidToken: "your order has been updated",
    defaultAddress: "Default Address",
    defaultAddressDesc:
      "Save this address as my default every time I receive a new order",
    deliverToOps: 'Deliver to',
  },
  ar: {
    welcomeMessage: "مرحبا",
    welcomeContent: "هناك شخص أرسل لك هدية من فلاورد. يرجى تعبئة تفاصيل عنوانك",
    deliverTimeTitle: "وقت التوصيل",
    addressDetailsTitle: "تفاصيل العنوان",
    chooseArea: "اختر المنطقه",
    addressPlaceHolder: "العنوان",
    extraAddressDetails: "إرشادات إضافية",
    confirmButton: "تأكيد",
    saveButton: "حفظ",
    thanksMessage: "شكرا",
    thanksContent: "تم تحديث طلبك بنجاح",
    delivery: "وقت التوصيل",
    deliverTo: "عنوان التوصيل",
    invalidToken: "جاري تحديث طلبك",
    defaultAddress: "العنوان الافتراضي",
    defaultAddressDesc:
      "احفظ هذا العنوان كعنوان افتراضي في كل مرة أتلقى فيها طلباً جديداً",
    deliverToOps: 'التوصيل إلى',
  },
};
