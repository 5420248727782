import React from "react";
import { useSelector, useDispatch } from "react-redux";
//selectors
import { getCallCenterModalOpen } from "../../store/modal/selectors/OpenCloseModalSelector";
//actions
import { setCallCenterModalFalse } from "../../store/modal/actions/OpenCloseModalAction";
//components
import Modal from "../../shared/Modal";
import CallCenterModalContent from "../../components/CallCenterModalContent";

const CallCenterModal = ({ lang }) => {
  const showCallCenter = useSelector((state) =>
      getCallCenterModalOpen({ state })
    ),
    dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(setCallCenterModalFalse());
  };

  return (
    <Modal
      isOpen={showCallCenter}
      cssClass="time-modal-style"
      handleCloseModal={handleCloseModal}
      modalContent={<CallCenterModalContent lang={lang} />}
      swipeToClose={true}
    />
  );
};

export default CallCenterModal;
