import React, { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
//actions
import { setMapModalOpenfalse } from "../../store/modal/actions/MapModalAction";
//selectors
import { getMapModalOpen } from "../../store/modal/selectors/MapModalSelector";
import { getLoaderIsOpen } from "../../store/modal/selectors/OpenCloseLoaderSelector";
//components
import Modal from "../../shared/Modal";
import MapModalContent from "../../components/MapModalContent";
//ionic components
import { IonContent, IonLoading } from "@ionic/react";
import { setOpenLoaderFalse } from "../../store/modal/actions/OpenCloseLoaderAction";

const MapModal = ({
  setUpdatedAreaId,
  setUpdatedAddress,
  setupdatedLat,
  setupdatedLng,
  lang,
  updatedLat,
  updatedLng,
}) => {
  const isMapModalOpen = useSelector((state) => getMapModalOpen({ state })),
    isLoaderOpen = useSelector((state) => getLoaderIsOpen({ state })),
    dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(setMapModalOpenfalse());
  };

  return (
    <Fragment>
      <Modal
        isOpen={isMapModalOpen}
        cssClass="map-modal-style"
        handleCloseModal={handleCloseModal}
        modalContent={
          <MapModalContent
            setUpdatedAreaId={setUpdatedAreaId}
            setUpdatedAddress={setUpdatedAddress}
            setupdatedLat={setupdatedLat}
            setupdatedLng={setupdatedLng}
            lang={lang}
            updatedLat={updatedLat}
            updatedLng={updatedLng}
          />
        }
      />
      <IonContent>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={isLoaderOpen}
          onDidDismiss={() => dispatch(setOpenLoaderFalse())}
          message={"Getting user location..."}
          // duration={2000}
        />
      </IonContent>
    </Fragment>
  );
};

export default MapModal;
