import React, { Fragment } from "react";
import { useSelector } from "react-redux";
//ionic
import { IonItem, IonRow, IonGrid } from "@ionic/react";
//selectors
import { getAreasList } from "../store/userData/selectors/userDataSelector";
import { getLanguage } from "../store/modal/selectors/SwitchLanguageSelector";
import { getCountryId } from "../store/userData/selectors/userDataSelector";

const SelectArea = ({
  setUpdatedAreaId,
  updatedAreaId,
  setUpdatedAddress,
  lang,
}) => {
  const areasList = useSelector((state) => getAreasList({ state })),
    currentLang = useSelector((state) => getLanguage({ state })),
    countryId = useSelector((state) => getCountryId({ state }));

  return (
    <Fragment>
      <IonGrid
        style={{
          width: "101%",
          marginTop: 20,
          direction: currentLang === "ar" ? "rtl" : "ltr",
          padding: "0 20px",
          display: "none",
        }}
      >
        <IonItem className="select-area-wrapper">
          <IonRow>
            <select
              className="select-area-style"
              value={updatedAreaId}
              onChange={(e) => {
                setUpdatedAddress("");
                setUpdatedAreaId(e.target.value);
              }}
            >
              {areasList.map((el) => {
                return (
                  <Fragment key={el.Area.Id}>
                    <optgroup
                      label={lang === "en" ? el.Area.Name : el.Area.NameAR}
                    >
                      <option value="" disabled="disabled">
                        {" "}
                        {lang === "en"
                          ? "-- select your area --"
                          : "-- اختر المنطقه --"}{" "}
                      </option>
                      {el.subarea.map((area) => (
                        <option key={area.Id} value={area.Id}>
                          {lang === "en" ? area.Name : area.NameAR}
                        </option>
                      ))}
                    </optgroup>
                  </Fragment>
                );
              })}
            </select>
          </IonRow>
        </IonItem>
      </IonGrid>
    </Fragment>
  );
};

export default SelectArea;
