import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { language } from "../shared/Language";
//ionnic components
import {
  IonPage,
  IonButton,
  IonRow,
  IonAlert,
  IonToast,
  IonGrid,
  IonContent,
  IonLoading,
} from "@ionic/react";
//components
import HomeHeader from "../components/HomeHeader";
import GiftMessage from "../components/GiftMessage";
import DeliverTime from "../components/DeliverTime";
import SelectArea from "../components/SelectArea";
import AddressDetails from "../components/AddressDetails";
import DropByWords from "../components/DropByWords";
// import ChangeTimeModal from "../components/Modals/ChangeTimeModal";
import MapModal from "../components/Modals/MapModal";
import CallCenterModal from "../components/Modals/CallCenterModal";
//actions
import { setUserData } from "../store/userData/actions/userDataAction";
import { setLanguge } from "../store/modal/actions/SwitchLanguageAction";
//selectors
import { getLanguage } from "../store/modal/selectors/SwitchLanguageSelector";
import { getAddressExtraDetails } from "../store/addressdetails/AddressDetailsSelector";
import { getCountryId } from "../store/userData/selectors/userDataSelector";
import { getCallCenterModalOpen } from "../store/modal/selectors/OpenCloseModalSelector";
import { getMapModalOpen } from "../store/modal/selectors/MapModalSelector";
//api
import LocationService from "../services/LocationService";

const Home = ({
  match: {
    params: { token, lang },
  },
  history,
}) => {
  const [showAlert, setShowAlert] = useState(false),
    [updatedAreaId, setUpdatedAreaId] = useState(""),
    [updatedAddress, setUpdatedAddress] = useState(""),
    [updatedLat, setupdatedLat] = useState(""),
    [updatedLng, setupdatedLng] = useState(""),
    [postalCode, setPostalCode] = useState(""),
    [isDefaultAddress, setIsDefaultAddress] = useState(true),
    currentLang = useSelector((state) => getLanguage({ state })),
    addressExtraDetails = useSelector((state) =>
      getAddressExtraDetails({ state })
    ),
    countryId = useSelector((state) => getCountryId({ state })),
    [isToastOpen, setIsToastOpen] = useState(false),
    [errormessage, setErrorMessage] = useState(""),
    showCallCenter = useSelector((state) => getCallCenterModalOpen({ state })),
    isMapModalOpen = useSelector((state) => getMapModalOpen({ state })),
    [showLoading, setShowLoading] = useState(false),
    dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUserData(token, lang));
    dispatch(setLanguge(lang));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSendData = async () => {
    if (updatedAddress) {
      const data = {
        token: token,
        lat: updatedLat,
        lng: updatedLng,
        addressLineOne: updatedAddress,
        areaId: updatedAreaId ? updatedAreaId : -1,
        extra: addressExtraDetails,
        postalCode: postalCode,
        isDefaultAddress: isDefaultAddress,
      };
      setShowLoading(true);
      try {
        await LocationService.sendData(data);
        setShowLoading(false);
        history.push(`/${lang}/${token}/done`);
      } catch (err) {
        setShowLoading(false);
        setErrorMessage(err.response.data.message);
        setIsToastOpen(true);
      }
    } else {
      setShowAlert(true);
    }
  };

  return (
    <IonPage
      className='app-wrapper'
      style={{
        fontFamily: lang === "en" ? "Gilroy-Medium" : "FedraSansArabic4",
      }}>
      <IonContent>
        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={"Please wait..."}
        />
        <IonGrid>
          {isToastOpen && (
            <IonToast
              isOpen={isToastOpen}
              onDidDismiss={() => setIsToastOpen(false)}
              message={errormessage}
              position='top'
              color='danger'
              duration={2000}
            />
          )}
          {showAlert && (
            <IonAlert
              isOpen={showAlert}
              onDidDismiss={() => setShowAlert(false)}
              cssClass='my-custom-class'
              message={
                lang === "en"
                  ? "Please provide your address."
                  : "من فضلك ادخل العنوان"
              }
              buttons={["OK"]}
            />
          )}
          {/* <ChangeTimeModal /> */}
          {isMapModalOpen && (
            <MapModal
              setUpdatedAreaId={setUpdatedAreaId}
              setUpdatedAddress={setUpdatedAddress}
              setupdatedLat={setupdatedLat}
              setupdatedLng={setupdatedLng}
              lang={lang}
              updatedLat={updatedLat}
              updatedLng={updatedLng}
            />
          )}
          {showCallCenter && <CallCenterModal lang={lang} />}
          <HomeHeader />
          <GiftMessage />
          <DeliverTime />
          {countryId != 8 && (
            <DropByWords
              setUpdatedAreaId={setUpdatedAreaId}
              setUpdatedAddress={setUpdatedAddress}
              setupdatedLat={setupdatedLat}
              setupdatedLng={setupdatedLng}
              lang={lang}
            />
          )}
          <SelectArea
            setUpdatedAreaId={setUpdatedAreaId}
            updatedAreaId={updatedAreaId}
            setUpdatedAddress={setUpdatedAddress}
            updatedAddress={updatedAddress}
            lang={lang}
          />
          <AddressDetails
            updatedAddress={updatedAddress}
            setUpdatedAddress={setUpdatedAddress}
            setPostalCode={setPostalCode}
            isDefaultAddress={isDefaultAddress}
            setIsDefaultAddress={setIsDefaultAddress}
          />
          <IonRow
            style={{
              marginTop: 15,
              width: "100%",
              // position: "absolute",
              // bottom: 7,
            }}>
            <IonButton
              style={{
                width: "93%",
                margin: "0 auto",
              }}
              color='primary'
              onClick={handleSendData}
              disabled={
                countryId == 8 || countryId == 5
                  ? !updatedAddress
                  : !updatedAddress || !updatedAreaId
              }>
              {language[currentLang].saveButton}
            </IonButton>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Home;
